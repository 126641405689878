import React from "react"
import { graphql } from "gatsby"

import MenuLayout from "../components/menulayout"
import SEO from "../components/seo"
import Thumb from "../components/thumb"

const Portfolio = ({ data }) => {
  return (
    <MenuLayout>
      <SEO title="Portfolio" />
      <div id="portfolio" className="row">
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <Thumb
            node={node}
            key={node.id}
            backLocation={
              typeof window !== "undefined" ? window.location.href : ""
            }
            colSpan={6}
          />
        ))}
      </div>
    </MenuLayout>
  )
}

export default Portfolio

export const query = graphql`
         query {
           allMarkdownRemark(
             filter: { frontmatter: { status: { eq: "publish" } } }
             sort: { fields: [frontmatter___date], order: DESC }
           ) {
             edges {
               node {
                 id
                 frontmatter {
                   title
                   status
                   thumb {
                     childImageSharp {
                       fluid(maxWidth: 1000, quality: 100) {
                         ...GatsbyImageSharpFluid
                       }
                     }
                   }
                 }
                 fields {
                   slug
                 }
               }
             }
           }
         }
       `
